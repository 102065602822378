<script>
import { Pie } from 'vue-chartjs'
import currencyView from '@/filters/currency-view.filter'
import i18n from '@/i18n'

export default {
  extends: Pie,
  props: {
    chartData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: (tooltipItems, data) => `${i18n.t('historyPage.Spent')}: ${currencyView(data.datasets[0].data[tooltipItems.index])}`
        }
      }
    }
  }),
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
