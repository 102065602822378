<template>
  <table>
      <thead>
      <tr>
        <th>#</th>
        <th>{{$t('historyPage.Amount')}}</th>
        <th>{{$t('historyPage.Date')}}</th>
        <th>{{$t('historyPage.Category')}}</th>
        <th>{{$t('historyPage.Type')}}</th>
        <th>{{$t('historyPage.Details')}}</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(record, index) of historyTable" :key="record.id">
        <td>{{index + 1 + recordsPerPage*(page - 1)}}</td>
        <td>{{record.amount | currencyView}}</td>
        <td>{{record.date | date('datetime')}}</td>
        <td>{{record.categoryName}}</td>
        <td>
            <span
              class="white-text badge"
              :class="[record.typeColor]"
            >{{$t(`historyPage.${record.type}`)}}</span>
        </td>
        <td>
          <button class="btn-small btn" @click="$router.push(`/detail-record/${record.id}`)" v-tooltip="$t('historyPage.Open')">
            <i class="material-icons">open_in_new</i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>

export default {
  name: 'historyTable',
  props: {
    historyTable: {
      required: true,
      type: Array
    },
    page: {
      required: true,
      type: Number
    },
    recordsPerPage: {
      required: true,
      type: Number
    }

  }
}
</script>

<style scoped>

</style>
