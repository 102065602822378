<template>
  <div>
    <div class="page-title">
      <h3>{{$t('historyPage.History')}}</h3>
    </div>
    <loader v-if="loading"/>
    <p class="center" v-else-if="!chartData">{{$t('historyPage.RecordsAbsent')}}
      {{chartData}}
      <router-link
        :to="'/record'"
      >{{$t('historyPage.AddNewRecord')}}</router-link>
    </p>
    <section v-else>
      <div class="history-chart">
        <pie-chart
          :chartData="chartData"
        />
      </div>
      <history-table :historyTable="items" :page="page" :recordsPerPage="recordsPerPage"/>
      <paginate
        class="center"
        v-model="page"
        :page-count="pageAmount"
        :click-handler="pagiFunction"
        :prev-text="this.$t('global.Prev')"
        :next-text="this.$t('global.Next')"
        :container-class="'pagination'"
      />
    </section>
  </div>
</template>

<script>
import historyTable from '@/components/history/historyTable'
import paginationMixins from '@/mixins/pagination.mixin'
import pieChart from '@/components/history/historyChart'
export default {
  name: 'History',
  data () {
    return {
      loading: true,
      updatedRecords: [],
      chartData: {}
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('menu.History')
    }
  },
  mixins: [paginationMixins],
  components: {
    historyTable,
    pieChart
  },
  async mounted () {
    const categories = await this.$store.dispatch('fetchCategories')
    const records = await this.$store.dispatch('fetchRecords')
    this.updatedRecords = records.map(record => {
      return {
        ...record,
        type: record.type === 'income' ? 'Income' : 'Outcome',
        typeColor: record.type === 'income' ? 'green' : 'red',
        categoryName: categories.find(cat => cat.id === record.categoryId).name
      }
    })
    const amountByCategory = categories
      .map(category => {
        return records.reduce((total, r) => {
          if (category.id === r.categoryId && r.type === 'outcome') {
            total += +r.amount
          }
          return total
        }, 0)
      })
    this.chartData = {
      labels: categories.map(category => category.name),
      datasets: [
        {
          data: amountByCategory,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]
    }

    this.startPagination(this.updatedRecords)
    this.loading = false
  }
}
</script>

<style scoped>

</style>
